
.noticia .instagram-media {
    margin: 10px auto !important;
    text-align: center;
}

.noticia figure.image {
    display: table;
    margin-left: auto;
    margin-right: auto;
}
 
.noticia figure.image img { 
    display: block;
}
 
.noticia figure.image figcaption {
    display: table-caption;
    caption-side: bottom;
    background-color: #efefef;
    color: #000;
    padding:3px;
    font-size: 0.9em;
    font-style: italic;
    margin-bottom: 0.5em;
}


@media screen and (max-width: 40em) and (orientation: portrait) {
    .noticia p {
      margin-left: 0.5em;
      margin-right: 0.5em;
    }
}