@font-face {
    font-family: 'Oferta do Dia';
    src: url('OfertadoDia.eot');
    src: local('Oferta do Dia Regular'), local('OfertadoDia'),
        url('OfertadoDia.eot?#iefix') format('embedded-opentype'),
        url('OfertadoDia.woff2') format('woff2'),
        url('OfertadoDia.woff') format('woff'),
        url('OfertadoDia.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

