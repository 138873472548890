#ParticipantesCarousel img {
    width: 100%;
    object-fit: cover;
    height: 608px;
    cursor: pointer !important;
}

#ParticipantesCarousel .wrapper {
    -ms-perspective: 800px;
    perspective: 800px;
    position: relative;
    width: 100%;
    height: 608px;
}
/* 
#ParticipantesCarousel  .front,
#ParticipantesCarousel a {
    cursor: pointer !important;
} */

#ParticipantesCarousel svg {
    pointer-events: none;
}

#ParticipantesCarousel .card2 {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d;
    transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    border-radius: 6px;
    /* box-shadow: 0 6px 16px rgba(0,0,0,0.15); */
}

#ParticipantesCarousel .card2 > div {
    position: absolute;
    top: 0;
    left: 0;
    /* width: 100%;
    height: 100%; */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 6px;
    background: #fff;
}
#ParticipantesCarousel .card2 .back {
  transform: rotateY(180deg);
  color: #ffffff;
}

#ParticipantesCarousel .card2.flipped {
  transform: rotateY(180deg);
}

#ParticipantesCarousel:not(.swiper-initialized) {
    max-height: unset;
    height: unset;
    overflow: hidden;
}

#ParticipantesCarousel:not(.swiper-initialized) .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    height: unset;
    max-height: 608px;
}

#ParticipantesCarousel:not(.swiper-initialized) .swiper-slide {
    width: calc(25% - 4px);
    margin-left: 4px;
}

#ParticipantesCarousel:not(.swiper-initialized) .swiper-slide:first-child {
    margin-left: 0;
}

/* #ParticipantesCarousel .sm a{
  display:inline-flex;
  width:40px;
  height:40px;
  text-decoration:none;
  justify-content:center;
  align-items: center;
  color: var(--dark);
  font-size:24px;
  transition:0.4s;
  border-radius:50%;
} */

#ParticipantesCarousel .swiper-button-next, 
#ParticipantesCarousel .swiper-button-prev {
    color: #000000; 
}

/* #ParticipantesCarousel  .sm a:hover{
  background: var(--dark);
  color:var(--white);
} */

@media screen and (max-width: 40em) and (orientation: portrait) {
    #ParticipantesCarousel:not(.swiper-initialized) .swiper-wrapper {
        height: unset;
        max-height: unset;
    }
    #ParticipantesCarousel:not(.swiper-initialized) .swiper-slide {
        display: none !important;
        width: 100%;
    }
    #ParticipantesCarousel:not(.swiper-initialized) .swiper-slide:first-child {
        display: block !important;
    }
  #ParticipantesCarousel img,
  #ParticipantesCarousel .wrapper {
    height: 90vh;
  }
}
