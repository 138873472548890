@font-face {
  font-family: Oferta do Dia;
  src: url("OfertadoDia.8b068c26.eot");
  src: local(Oferta do Dia Regular), local(OfertadoDia), url("OfertadoDia.8b068c26.eot#iefix") format("embedded-opentype"), url("OfertadoDia.3dc922c1.woff2") format("woff2"), url("OfertadoDia.4f5c83bc.woff") format("woff"), url("OfertadoDia.3ec072ab.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

header .text-4xl, header .text-2xl, header nav, .CustomFont {
  font-family: Oferta do Dia, sans-serif;
}

body {
  background: #ffda8b url("bg-desktop.7d2a2255.jpg") top no-repeat;
}

footer .bg {
  aspect-ratio: 1.75583;
  background: url("bg-rodape-desktop.38edfa92.png") top / cover no-repeat;
  width: 100%;
  max-width: 1280px;
  max-height: 729px;
  margin: 0 auto;
}

@media screen and (width <= 40em) and (orientation: portrait) {
  footer .bg {
    aspect-ratio: .29;
    background: url("bg-rodape-m.8d7e4ef8.png") top / cover no-repeat;
    width: 100%;
    max-width: 1073px;
    max-height: 3700px;
    margin: 0 auto;
  }
}

#BannersCarousel {
  z-index: 1000;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide {
  display: none;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide:first-child {
  width: 100%;
  height: auto;
  display: block;
  aspect-ratio: 2.04651 !important;
  max-height: auto !important;
}

#BannersCarousel .swiper-slide {
  text-align: center;
}

#BannersCarousel .swiper-slide img {
  width: 100%;
  max-width: 1320px;
  height: auto;
  max-height: 645px;
  aspect-ratio: 2.04651 !important;
}

#BannersCarousel .swiper-button-next, #BannersCarousel .swiper-button-prev {
  color: #000 !important;
}

#BannersCarousel .swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper {
  flex-wrap: wrap;
  max-height: 320px;
  display: flex;
  overflow: hidden;
}

.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
  width: calc(25% - 8px);
  margin: 0 4px;
}

.HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
  max-height: 320px;
}

.HomeSliders.default .swiper-wrapper {
  height: 30vh;
  max-height: 250px;
}

.HomeSliders.default img {
  object-fit: cover;
  width: 100%;
  height: 27vh;
  max-height: 220px;
}

.HomeSliders.default {
  font-size: 1em;
  font-weight: 500;
  line-height: 1.2;
}

.HomeSliders.default a {
  text-decoration: none;
  display: block;
  position: relative;
}

.HomeSliders.default a:hover {
  color: var(--white);
}

.HomeSliders.default .title {
  text-align: center;
  background-color: #000000b3;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 2.5em;
  padding: 0 .25em;
  font-size: .9em;
  font-weight: 600;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.swiper-button-next, .swiper-button-prev {
  color: #000 !important;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

@media screen and (width <= 40em) and (orientation: portrait) {
  #BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide:first-child {
    width: 100%;
    height: auto;
    display: block;
    aspect-ratio: .679348 !important;
    max-height: auto !important;
  }

  #BannersCarousel .swiper-slide img {
    width: 100%;
    max-width: 100%;
    height: auto;
    aspect-ratio: .679348 !important;
  }

  .HomeSliders.default .swiper-wrapper {
    max-height: unset;
    height: 38vh;
  }

  .HomeSliders.default img {
    object-fit: cover;
    width: 100%;
    height: auto;
    max-height: 35vh !important;
  }

  .HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
    max-height: unset;
  }
}

.noticia .instagram-media {
  text-align: center;
  margin: 10px auto !important;
}

.noticia figure.image {
  margin-left: auto;
  margin-right: auto;
  display: table;
}

.noticia figure.image img {
  display: block;
}

.noticia figure.image figcaption {
  caption-side: bottom;
  color: #000;
  background-color: #efefef;
  margin-bottom: .5em;
  padding: 3px;
  font-size: .9em;
  font-style: italic;
  display: table-caption;
}

@media screen and (width <= 40em) and (orientation: portrait) {
  .noticia p {
    margin-left: .5em;
    margin-right: .5em;
  }
}

#ParticipantesCarousel img {
  object-fit: cover;
  width: 100%;
  height: 608px;
  cursor: pointer !important;
}

#ParticipantesCarousel .wrapper {
  -ms-perspective: 800px;
  perspective: 800px;
  width: 100%;
  height: 608px;
  position: relative;
}

#ParticipantesCarousel svg {
  pointer-events: none;
}

#ParticipantesCarousel .card2 {
  transform-style: preserve-3d;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: transform .8s cubic-bezier(.175, .885, .32, 1.275);
  position: absolute;
}

#ParticipantesCarousel .card2 > div {
  backface-visibility: hidden;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
}

#ParticipantesCarousel .card2 .back {
  color: #fff;
  transform: rotateY(180deg);
}

#ParticipantesCarousel .card2.flipped {
  transform: rotateY(180deg);
}

#ParticipantesCarousel:not(.swiper-initialized) {
  max-height: unset;
  height: unset;
  overflow: hidden;
}

#ParticipantesCarousel:not(.swiper-initialized) .swiper-wrapper {
  height: unset;
  flex-wrap: wrap;
  max-height: 608px;
  display: flex;
}

#ParticipantesCarousel:not(.swiper-initialized) .swiper-slide {
  width: calc(25% - 4px);
  margin-left: 4px;
}

#ParticipantesCarousel:not(.swiper-initialized) .swiper-slide:first-child {
  margin-left: 0;
}

#ParticipantesCarousel .swiper-button-next, #ParticipantesCarousel .swiper-button-prev {
  color: #000;
}

@media screen and (width <= 40em) and (orientation: portrait) {
  #ParticipantesCarousel:not(.swiper-initialized) .swiper-wrapper {
    height: unset;
    max-height: unset;
  }

  #ParticipantesCarousel:not(.swiper-initialized) .swiper-slide {
    width: 100%;
    display: none !important;
  }

  #ParticipantesCarousel:not(.swiper-initialized) .swiper-slide:first-child {
    display: block !important;
  }

  #ParticipantesCarousel img, #ParticipantesCarousel .wrapper {
    height: 90vh;
  }
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-6 {
  right: 1.5rem;
}

.top-0 {
  top: 0;
}

.top-6 {
  top: 1.5rem;
}

.isolate {
  isolation: isolate;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.float-left {
  float: left;
}

.clear-both {
  clear: both;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-52 {
  margin-bottom: 13rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.aspect-square {
  aspect-ratio: 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-14 {
  height: 3.5rem;
}

.h-24 {
  height: 6rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-8 {
  height: 2rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-96 {
  min-height: 24rem;
}

.min-h-full {
  min-height: 100%;
}

.w-0 {
  width: 0;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-48 {
  width: 12rem;
}

.w-6 {
  width: 1.5rem;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.max-w-full {
  max-width: 100%;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.table-auto {
  table-layout: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.resize {
  resize: both;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.place-items-stretch {
  place-items: stretch stretch;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}

.self-center {
  align-self: center;
}

.overflow-hidden {
  overflow: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyphens-auto {
  hyphens: auto;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-35 {
  --tw-bg-opacity: .35;
}

.bg-opacity-50 {
  --tw-bg-opacity: .5;
}

.bg-opacity-65 {
  --tw-bg-opacity: .65;
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-contain {
  background-size: contain;
}

.bg-cover {
  background-size: cover;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.text-center {
  text-align: center;
}

.text-start {
  text-align: start;
}

.align-middle {
  vertical-align: middle;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.\!leading-relaxed {
  line-height: 1.625 !important;
}

.leading-relaxed {
  line-height: 1.625;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-inset {
  --tw-ring-inset: inset;
}

.ring-amber-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity));
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

#sapr {
  aspect-ratio: 1.88235;
  background: url("bg-s1-d.min.b25f3b5f.png") top / cover no-repeat;
  max-width: 1280px;
  max-height: 680px;
}

footer {
  aspect-ratio: 2.77056;
  background: url("bg-f-d.min.33ee8760.png") top / cover no-repeat;
  max-width: 1280px;
  max-height: 462px;
}

.imgf {
  height: 250px;
}

#cardContainer {
  width: 320px;
  left: -320px;
  z-index: 1040 !important;
}

@media screen and (width <= 40em) and (orientation: portrait) {
  #sapr {
    background: unset;
    max-width: unset;
    aspect-ratio: unset;
    max-height: unset;
  }

  footer {
    max-width: unset;
    max-height: unset;
    aspect-ratio: unset;
    background: url("bg-r-m.min.f8bd0a80.png") top / cover no-repeat;
  }

  body {
    background: #ffda8b url("bg-m-2.min.dd645870.jpg") top / cover no-repeat;
  }

  .imgf {
    height: auto;
  }
}

.hover\:bg-amber-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.hover\:bg-amber-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:z-20:focus {
  z-index: 20;
}

.focus\:outline-offset-0:focus {
  outline-offset: 0px;
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-2:focus-visible {
  outline-width: 2px;
}

.focus-visible\:outline-offset-2:focus-visible {
  outline-offset: 2px;
}

.focus-visible\:outline-amber-600:focus-visible {
  outline-color: #d97706;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

@media (width >= 640px) {
  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-11\/12 {
    width: 91.6667%;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:max-w-screen-sm {
    max-width: 640px;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:rounded-b-lg {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@media (width >= 1024px) {
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:rounded-lg {
    border-radius: .5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (width >= 1280px) {
  .xl\:max-w-screen-lg {
    max-width: 1024px;
  }

  .xl\:max-w-screen-xl {
    max-width: 1280px;
  }

  .xl\:bg-cover {
    background-size: cover;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
}
/*# sourceMappingURL=index.9beb819b.css.map */
