
#BannersCarousel {
    z-index: 1000;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide {
    display: none;
}

#BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide:first-child {
    display: block;
    aspect-ratio: calc(1320/645) !important;
    height: auto;
    max-height: auto !important;
    width: 100%;
}

#BannersCarousel .swiper-slide {
    text-align: center;
}

#BannersCarousel .swiper-slide img {
    width: 100%;
    max-width: 1320px;
    height: auto;
    max-height: 645px;
    aspect-ratio: calc(1320/645) !important;
}

#BannersCarousel .swiper-button-next, 
#BannersCarousel .swiper-button-prev {
    color: #000000 !important; 
}

#BannersCarousel .swiper-pagination-bullet-active {
    background-color: #000000 !important; 
}

.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-height: 320px;
    overflow: hidden;
}

.HomeSliders.default .swiper:not(.swiper-initialized) .swiper-wrapper .swiper-slide {
    margin: 0 4px;
    width: calc(25% - 8px);
}

.HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
    max-height: 320px;
}

.HomeSliders.default .swiper-wrapper {
    height: 30vh;
    max-height: 250px;
}

.HomeSliders.default img {
    height: 27vh;
    max-height: 220px;
    object-fit: cover;
    width: 100%;
}

.HomeSliders.default {
    font-size: 1em;
    font-weight: 500;
    line-height: 1.2;
}

.HomeSliders.default a {
    text-decoration: none;
    display: block;
    position: relative;
}

.HomeSliders.default a:hover {
    color: var(--white);
}

.HomeSliders.default .title {
  position: absolute;
  bottom: 0;
  width: 100%;
  min-height: 2.5em;
  background-color: rgba(0,0,0,0.7);
  text-align: center;
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 0.25em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-next, 
.swiper-button-prev  {
    color: #000000 !important; 
}

.swiper-pagination-bullet-active {
    background-color: #000000 !important; 
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    #BannersCarousel.swiper:not(.swiper-initialized) .swiper-slide:first-child {
        display: block;
        aspect-ratio: calc(375/552) !important;
        height: auto;
        max-height: auto !important;
        width: 100%;
    }
    #BannersCarousel .swiper-slide img {
        width: 100%;
        max-width: 100%;
        height: auto;
        aspect-ratio: calc(375/552) !important;
    }
  .HomeSliders.default .swiper-wrapper {
    height: 38vh;
    max-height: unset;
  }
  .HomeSliders.default img {
    width: 100%;
    height: auto;
    max-height: 35vh !important;
    object-fit: cover;
  }
  .HomeSliders.default .swiper img.swiper-lazy:not(.swiper-lazy-loaded) {
    max-height: unset;
  }
}
