@import url(../fonts/stylesheet.css);

header .text-4xl,
header .text-2xl,
header nav,
.CustomFont {
    font-family: 'Oferta do Dia', sans-serif;
}

body {
    background: url(../img/bg-desktop.jpg) no-repeat top center;
    background-color: #ffda8b;
}

footer .bg {
    background: url(../img/bg-rodape-desktop.png) no-repeat top/cover;
    max-height: 729px;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    aspect-ratio: calc(1280/729);
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    footer .bg {
        background: url(../img/bg-rodape-m.png) no-repeat top/cover;
        max-height: 3700px;
        width: 100%;
        max-width: 1073px;
        margin: 0 auto;
        aspect-ratio: calc(1073/3700);
    }    
}

