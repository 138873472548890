@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(common.css);
@import url(widgets.css);
@import url(noticia.css);
@import url(widget-participantes.css);

#sapr {
    background: url(../img/bg-s1-d.min.png) no-repeat top/cover;
    max-width: 1280px;
    max-height: 680px;
    aspect-ratio: calc(1280/680);
}

footer {
    background: url(../img/bg-f-d.min.png) no-repeat top/cover;
    max-width: 1280px;
    max-height: 462px;
    aspect-ratio: calc(1280/462);
}

.imgf {
    height: 250px;
}

#cardContainer {
    z-index: 1040 !important;
    left: -320px;
    width: 320px;
}

@media screen and (max-width: 40em) and (orientation: portrait) {
    #sapr {
        background: unset;
        max-width: unset;
        aspect-ratio: unset;
        max-height: unset;    
    }

    footer {
        background: url(../img/bg-r-m.min.png) no-repeat top/cover;
        max-width: unset;
        max-height: unset;
        aspect-ratio: unset;
    }

    body {
        background: url(../img/bg-m-2.min.jpg) no-repeat top/cover;
        background-color: #ffda8b;
    }

    .imgf {
        height: auto;
    }
}
